import apiClient from "./axiosInstance"

export const TAdoVisibleCampaign = async (id: string, visibility: any) => {
  const query = new URLSearchParams({
    id,
    visibility,
  })

  const response = await apiClient.post(`/admin/campaign/visible?${query}`, {}, {})

  if (response.status === 200) {
    return response
  }
}
export const TAfindCampaign = async (data: any) => {
  let query = ""
  if (data.name) {
    query = `?name=${data.name}`
  }

  const response = await apiClient.get(`/admin/campaign/get${query}`, {})

  if (response.status === 200) {
    return response
  } else {
    throw new Error("Find Campaign failed")
  }
}

export const TAfindApprovedCampaigns = async (page: number, perPage: number) => {
  const response = await apiClient.get(
    `/admin/campaign/getverifiedcampaign?page=${page}&perPage=${perPage}`,
    {},
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Campaign failed")
  }
}

export const TAfindCampaignById = async (_id: any) => {
  const response = await apiClient.get(`/admin/campaign/_id/${_id}`, {})
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAsetVisibility = async (_id: string, status: boolean) => {
  const response = await apiClient.get(
    `/admin/campaign/set-visibilty?status=${status}&_id=${_id}`,
    {},
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Campaign failed")
  }
}

export const TAfindAllCampaigns = async (
  created_at: string,
  country: string,
  platform: string,
  is_verified: string,
  visibility: string,
  max_cost: string,
  gender: string,
  min_follower: string,
  max_follower: string,
  min_age: string,
  max_age: string,
  sortBy: string,
  page: number,
  perPage: number,
  campaign: string,
) => {
  const response = await apiClient.get(
    `/admin/campaign/getall?created_at=${created_at}&country=${country}&platform=${platform}&is_verified=${is_verified}&visibility=${visibility}&max_cost=${max_cost}&gender=${gender}&min_follower=${min_follower}&max_follower=${max_follower}&min_age=${min_age}&max_age=${max_age}&sortBy=${sortBy}&page=${page}&perPage=${perPage}&campaign=${campaign}`,
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Campaign failed")
  }
}

export const TAfindApprovalCampaign = async () => {
  const response = await apiClient.get("/admin/campaign/getverificationcampaign", {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Campaign failed")
  }
}

export const TAdoApprovalCampaign = async (status: string, rejected_reason: any, id: string) => {
  const query = new URLSearchParams({
    id,
    status,
    rejected_reason,
  })

  const response = await apiClient.post(`/admin/campaign/verificate?${query}`)

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Approval Campaign failed")
  }
}

export const TAupdateCampaignNotes = async (id: string, notes: string[]) => {
  await apiClient.put(`/admin/campaign/updatenote/${id}`, { notes })
}

export const TAspamCampaign = async (_id: any, is_spam: any) => {
  const response = await apiClient.put(`/admin/campaign/setspamcampaign/${_id}/${is_spam}`, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Approval Campaign failed")
  }
}

export const TAupdateCampaign = async (id: any, data: any) => {
  const response = await apiClient.put(`/admin/campaign/updatecampaign/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Approval Campaign failed")
  }
}

export const TAupdateCampaignLogo = async (id: any, data: any) => {
  const response = await apiClient.put(`/admin/campaign/updatecoverphoto/${id}`, data, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent></calculated>",
    },
  })

  return response
}
