interface JobItem {
  label: string
  value: string
}
export const jobsArray: JobItem[] = [
  { label: "unemployed", value: "unemployed" },
  { label: "Family doctor", value: "Family doctor" },
  { label: "Actor", value: "Actor" },
  { label: "Colonel", value: "Colonel" },
  { label: "Anesthesiologist", value: "Anesthesiologist" },
  { label: "Animator", value: "Animator" },
  { label: "Car mechanic", value: "Car mechanic" },
  { label: "Assistant", value: "Assistant" },
  { label: "Soldier", value: "Soldier" },
  { label: "Astrophysicist", value: "Astrophysicist" },
  { label: "Astrologer", value: "Astrologer" },
  { label: "Astronomer", value: "Astronomer" },
  { label: "Astronaut", value: "Astronaut" },
  { label: "Athlete", value: "Athlete" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Chef", value: "Chef" },
  { label: "Gardener", value: "Gardener" },
  { label: "Grocer", value: "Grocer" },
  { label: "Fisherman", value: "Fisherman" },
  { label: "Banker", value: "Banker" },
  { label: "Barman", value: "Barman" },
  { label: "Basketball player", value: "Basketball player" },
  { label: "Barber", value: "Barber" },
  { label: "Composer", value: "Composer" },
  { label: "Information Processor", value: "Information Processor" },
  { label: "Computer engineer", value: "Computer engineer" },
  { label: "Computer programmer", value: "Computer programmer" },
  { label: "Computer technician", value: "Computer technician" },
  { label: "Biographer", value: "Biographer" },
  { label: "Biologist", value: "Biologist" },
  { label: "Broker", value: "Broker" },
  { label: "Painter", value: "Painter" },
  { label: "environmental engineer", value: "environmental engineer" },
  { label: "Farmer", value: "Farmer" },
  { label: "Locksmith", value: "Locksmith" },
  { label: "Diver", value: "Diver" },
  { label: "Consultant", value: "Consultant" },
  { label: "Diplomat", value: "Diplomat" },
  { label: "nutritionist", value: "nutritionist" },
  { label: "Dentist", value: "Dentist" },
  { label: "Doctor", value: "Doctor" },
  { label: "Ice cream shop", value: "Ice cream shop" },
  { label: "Dubbing", value: "Dubbing" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "Editor", value: "Editor" },
  { label: "Real estate agent", value: "Real estate agent" },
  { label: "Esthetician", value: "Esthetician" },
  { label: "Housewife", value: "Housewife" },
  { label: "Fortune teller", value: "Fortune teller" },
  { label: "Extra", value: "Extra" },
  { label: "Physicist", value: "Physicist" },
  { label: "Photographer", value: "Photographer" },
  { label: "Footballer", value: "Footballer" },
  { label: "Gallery", value: "Gallery" },
  { label: "Gamer", value: "Gamer" },
  { label: "Waiter", value: "Waiter" },
  { label: "Journalist", value: "Journalist" },
  {
    label: "Revenue Assistant Specialist",
    value: "Revenue Assistant Specialist",
  },
  { label: "Graphic artist", value: "Graphic artist" },
  { label: "Optician", value: "Optician" },
  { label: "Beauty expert", value: "Beauty expert" },
  { label: "Reporter", value: "Reporter" },
  { label: "Referee", value: "Referee" },
  { label: "Topographical engineer", value: "Topographical engineer" },
  { label: "Calligrapher", value: "Calligrapher" },
  { label: "Pet sitter", value: "Pet sitter" },
  { label: "Nurse", value: "Nurse" },
  { label: "Sculptor", value: "Sculptor" },
  { label: "Shoo", value: "Shoo" },
  { label: "Executive officer", value: "Executive officer" },
  { label: "Economist", value: "Economist" },
  { label: "Theologian", value: "Theologian" },
  { label: "Construction engineer", value: "Construction engineer" },
  { label: "Fireman", value: "Fireman" },
  { label: "Operator", value: "Operator" },
  { label: "Worker", value: "Worker" },
  { label: "Geophysical engineer", value: "Geophysical engineer" },
  { label: "Geological engineer", value: "Geological engineer" },
  { label: "Crew member", value: "Crew member" },
  { label: "Cameraman", value: "Cameraman" },
  { label: "captain", value: "captain" },
  { label: "Doorman", value: "Doorman" },
  { label: "Cardiologist", value: "Cardiologist" },
  { label: "Cashier", value: "Cashier" },
  { label: "Housekeeper", value: "Housekeeper" },
  { label: "Kebab Restaurant", value: "Kebab Restaurant" },
  { label: "Chemical engineer", value: "Chemical engineer" },
  { label: "Chemist", value: "Chemist" },
  { label: "Bookstore", value: "Bookstore" },
  { label: "Comedian", value: "Comedian" },
  { label: "Commissioner", value: "Commissioner" },
  { label: "Deputy commissioner", value: "Deputy commissioner" },
  { label: "Hostess", value: "Hostess" },
  { label: "Cosmologist", value: "Cosmologist" },
  { label: "Hairdresser", value: "Hairdresser" },
  { label: "Messenger", value: "Messenger" },
  { label: "Jeweler", value: "Jeweler" },
  { label: "Ornithologist", value: "Ornithologist" },
  { label: "Dog trainer", value: "Dog trainer" },
  { label: "Columnist", value: "Columnist" },
  { label: "Stationer", value: "Stationer" },
  { label: "Restaurateur", value: "Restaurateur" },
  { label: "Miner", value: "Miner" },
  { label: "Mechanical engineer", value: "Mechanical engineer" },
  { label: "Machinist", value: "Machinist" },
  { label: "Make Up", value: "Make Up" },
  { label: "Model", value: "Model" },
  { label: "Carpenter", value: "Carpenter" },
  { label: "Masseur", value: "Masseur" },
  { label: "Officer", value: "Officer" },
  { label: "Marketing Manager", value: "Marketing Manager" },
  { label: "Marble shop", value: "Marble shop" },
  { label: "Seasonal worker", value: "Seasonal worker" },
  { label: "Fielder", value: "Fielder" },
  { label: "Deputy", value: "Deputy" },
  { label: "architect", value: "architect" },
  { label: "Fashion designer", value: "Fashion designer" },
  { label: "Correspondent", value: "Correspondent" },
  { label: "Accountant", value: "Accountant" },
  { label: "Mukhtar", value: "Mukhtar" },
  { label: "Inspector", value: "Inspector" },
  { label: "Engineer", value: "Engineer" },
  { label: "Musician", value: "Musician" },
  { label: "Notary", value: "Notary" },
  { label: "Teaching staff", value: "Teaching staff" },
  { label: "Lecturer", value: "Lecturer" },
  { label: "Teacher", value: "Teacher" },
  { label: "School principal", value: "School principal" },
  { label: "Player", value: "Player" },
  { label: "Private driver", value: "Private driver" },
  { label: "Clown", value: "Clown" },
  { label: "Mime", value: "Mime" },
  { label: "Pilot", value: "Pilot" },
  { label: "Police officer", value: "Police officer" },
  { label: "Professor", value: "Professor" },
  { label: "Psychiatrist", value: "Psychiatrist" },
  { label: "Psychologist", value: "Psychologist" },
  { label: "Advertiser", value: "Advertiser" },
  { label: "Poetrist", value: "Poetrist" },
  { label: "Industrialist", value: "Industrialist" },
  { label: "Singer", value: "Singer" },
  { label: "Moneychanger", value: "Moneychanger" },
  { label: "Salesman", value: "Salesman" },
  { label: "Public prosecutor", value: "Public prosecutor" },
  { label: "Secretary", value: "Secretary" },
  {
    label: "Independent accountant financial advisor",
    value: "Independent accountant financial advisor",
  },
  { label: "Insurer", value: "Insurer" },
  { label: "Wizard", value: "Wizard" },
  { label: "Chauffeur", value: "Chauffeur" },
  { label: "Announcer", value: "Announcer" },
  { label: "Designer", value: "Designer" },
  { label: "Lieutenant", value: "Lieutenant" },
  { label: "Technician", value: "Technician" },
  { label: "Technology expert", value: "Technology expert" },
  { label: "Theater director", value: "Theater director" },
  { label: "Tourism Professional", value: "Tourism Professional" },
  { label: "Transportation manager", value: "Transportation manager" },
  { label: "Urologist", value: "Urologist" },
  { label: "Senior Manager", value: "Senior Manager" },
  { label: "Foreman", value: "Foreman" },
  { label: "Space scientist", value: "Space scientist" },
  { label: "Space engineer", value: "Space engineer" },
  { label: "Flight technician", value: "Flight technician" },
  { label: "Preacher", value: "Preacher" },
  { label: "Governor", value: "Governor" },
  { label: "Tax inspector", value: "Tax inspector" },
  { label: "veterinary", value: "veterinary" },
  { label: "Treasurer", value: "Treasurer" },
  { label: "Video editor", value: "Video editor" },
  { label: "Lieutenant colonel", value: "Lieutenant colonel" },
  { label: "Assistant referee", value: "Assistant referee" },
  { label: "Co-pilot", value: "Co-pilot" },
  { label: "Judge", value: "Judge" },
  { label: "Investment specialist", value: "Investment specialist" },
  { label: "Publisher", value: "Publisher" },
  { label: "Author", value: "Author" },
  { label: "Managing editor", value: "Managing editor" },
  { label: "Software engineer", value: "Software engineer" },
  { label: "Sailing", value: "Sailing" },
  { label: "Manager", value: "Manager" },
  { label: "Director", value: "Director" },
  { label: "Swimmer", value: "Swimmer" },
  { label: "Police", value: "Police" },
]
