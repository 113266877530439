import apiClient from "./axiosInstance"

export const TASearchByUsername = async (username: string, type: string) => {
  const response = await apiClient.get(
    `/admin/user/search-by-username?username=${username}&type=${type}`,
    {
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Username search failed")
  }
}

export const TAfindApplicationByCampaignId = async (campaign_id: any, state: string) => {
  const response = await apiClient.get(
    `/admin/application/campaign_id/${campaign_id}?state=${state}`,
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TACreateApplication = async (
  applicationUser: {
    user_id: string
    insta_username: string
    age: number
    gender: string
    school_type: string
    // school_name: string,
    city: string
    country: string
    language: string
    currency: string
    job: string
    birthday: string
    verification: boolean
    hobbies: string[]
  },
  campaign_id: string,

  selectedState: string,
  useCredentials: any,
  campaign_type: string,
) => {
  const response = await apiClient.post(
    "/admin/application/create",
    {
      user: applicationUser,
      campaign_id: campaign_id,
      state: selectedState,
      use_credentials: useCredentials,
      campaign_type: campaign_type,
    },
    {
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Creating application failed")
  }
}

export const TAupdateApplication = async (update: any, _id: string) => {
  const response = await apiClient.post(
    "/admin/application/update",
    {
      _id: _id,
      update: update,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Updating application pricing failed")
  }
}

export const TAuploadUserPayload = async (id: any, data: any, type: string) => {
  const response = await apiClient.post(`/admin/application/upload/${id}?type=${type}`, data, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent></calculated>",
    },
  })
  return response
}

export const TAassignUserToApplication = async (id: any, data: any) => {
  const response = await apiClient.post(
    `/admin/application/assign-user/${id}`,
    { user_id: data },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  return response
}

export const TAfindApplications = async (query: any) => {
  const response = await apiClient.get(`/admin/application/user_applications?${query}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAgetAllApplications = async (
  page: number,
  perPage: number,
  params: any,

  sortBy: string,
  sortOrder: boolean,
) => {
  const response = await apiClient.get(
    `/admin/application/all_applications?${params}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}
