import React, { useEffect, useState } from "react"
import { InfoType } from "../types/hashtagSearchPostData"
import "./styles/styles.css"

const TeamfluencerInstaData = (data: any) => {
  const [insta, setInsta] = useState({
    id: "",
    username: "",
    full_name: "",
    biography: "",
    edge_followed_by: {
      count: 0,
    },
    edge_follow: {
      count: 0,
    },
    highlight_reel_count: 0,
    edge_owner_to_timeline_media: {
      count: 0,
    },
  })

  useEffect(() => {
    setInsta(data.data)
  }, [data])

  const postInfo: InfoType[] = [
    { key: "Account ID:", value: String(insta?.id) },
    { key: "Username:", value: String(insta?.username) },
    { key: "Full Name:", value: String(insta?.full_name) },
    { key: "Biography:", value: String(insta?.biography) },
    { key: "Media Count:", value: String(insta?.edge_owner_to_timeline_media.count) },
    { key: "Followers Count:", value: Number(insta?.edge_followed_by?.count) },
    { key: "Following Count:", value: Number(insta?.edge_follow?.count) },
    { key: "Highlight Reels Count:", value: String(insta?.highlight_reel_count) },
  ]

  return (
    <div className="post-info">
      {postInfo.map((info) => (
        <div key={info.key} className="flex post-info-item">
          <div className="post-info-item-key font-bold">{info.key}</div>
          <div className="pl-4 post-info-item-value">{info.value}</div>
        </div>
      ))}
    </div>
  )
}

export default TeamfluencerInstaData
