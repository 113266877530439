import React from "react"
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom"
import LoginScreen from "./screens/LoginScreen"
import RegisterScreen from "./screens/RegisterScreen"
import { login } from "./redux/store/userSlice"
import { useSelector, useDispatch } from "react-redux"
import FindUser from "./screens/FindUser"
import { useEffect, Suspense } from "react"
import CommonLayout from "./components/CommonLayout"
import WaitingApprovalUser from "./screens/WaitingApprovalUser"
import FindBrand from "./screens/FindBrand"
import FindCampaign from "./screens/FindCampaign"
import AllBrands from "./screens/AllBrands"
import AllCampaign from "./screens/AllCampaign"
import DoApprovalScreen from "./screens/DoApprovalScreen"
import APIdocsScreen from "./screens/APIdocsScreen"
import GetAllUsers from "./screens/GetAllUsers"
import DoApprovalCampaigns from "./screens/DoApprovalCampaigns"
import { selectToken } from "./redux/store/userSlice"
import ApprovedUsers from "./screens/ApprovedUsers"
import ApprovedCampaigns from "./screens/ApprovedCampaigns"
import Dashboard from "./screens/Dashboard"
import HashtagSearch from "./screens/HashtagSearch"
import PopularBrands from "./screens/PopularBrands"
import TAgetMostContentUsernames from "./screens/MostContentUsernames"
import TcNoControl from "./screens/TcNoControl"
import UserTaggedPostsScreen from "./screens/UserTaggedPostsScreen"
import SpamUnvisibleUsers from "./screens/SpamUnvisibleUsers"
import FindApplications from "./screens/FindApplications"
import ActiveUsers from "./screens/ActiveUsers"
import WpMessages from "./screens/WpMeesages"
import AllApplications from "./screens/AllApplications"

function App() {
  const token = useSelector(selectToken)
  const dispatch = useDispatch()

  useEffect(() => {
    const tokenString = localStorage.getItem("token")
    if (tokenString && !token) {
      dispatch(login({ token: tokenString }))
    }
  }, [dispatch, token])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/auth/*"
          element={token ? <Navigate to="/statistics/dashboard" /> : <AuthLayout />}
        />
        <Route path="/*" element={token ? <MainLayout /> : <Navigate to="/auth/login" />} />
        <Route path="/user/*" element={token ? <UserLayout /> : <AuthLayout />} />
        <Route path="/brands/*" element={token ? <BrandsLayout /> : <AuthLayout />} />
        <Route path="/campaigns/*" element={token ? <CampaignsLayout /> : <AuthLayout />} />
        <Route path="/statistics/*" element={token ? <StatisticsLayout /> : <AuthLayout />} />
        <Route path="/applications/*" element={token ? <ApplicationsLayout /> : <AuthLayout />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App

function AuthLayout() {
  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
    </Routes>
  )
}

function MainLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            {/* <Route path="/" element={<Index />} /> */}
            <Route path="/api-docs" element={<APIdocsScreen />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}

function UserLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/find" element={<FindUser />} />
            <Route path="/find-waiting-approval" element={<WaitingApprovalUser />} />
            <Route path="/do-approval" element={<DoApprovalScreen />} />
            <Route path="/getall" element={<GetAllUsers />} />
            <Route path="/find-approved-users" element={<ApprovedUsers />} />
            <Route path="/find/:id" element={<FindUser />} />
            <Route path="/user-tagged-posts" element={<UserTaggedPostsScreen />} />
            <Route path="/spam-unvisible-users" element={<SpamUnvisibleUsers />} />
            <Route path="/active-users" element={<ActiveUsers />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}

function BrandsLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/find" element={<FindBrand />} />
            <Route path="/find-all" element={<AllBrands />} />
            <Route path="/find/:id" element={<FindBrand />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}

function CampaignsLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/find/:_id" element={<FindCampaign />} />
            <Route path="/find-all" element={<AllBrands />} />
            <Route path="/do-approval" element={<DoApprovalCampaigns />} />
            <Route path="/find-all-campaigns" element={<AllCampaign />} />
            <Route path="/approved-campaigns" element={<ApprovedCampaigns />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}

function StatisticsLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/hashtag-search" element={<HashtagSearch />} />
            <Route path="/popular-brands" element={<PopularBrands />} />
            <Route path="/most-content-usernames" element={<TAgetMostContentUsernames />} />
            <Route path="/tcno-control" element={<TcNoControl />} />
            <Route path="/wp-messages" element={<WpMessages />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}

function ApplicationsLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/find/" element={<FindApplications />} />
            <Route path="/all-applications" element={<AllApplications />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}
