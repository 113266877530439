import React from "react"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { TAfindApplications } from "../services/applicationAPI"
import { setPageTitle } from "../redux/store/themeConfigSlice"
import { DataTable } from "mantine-datatable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faMars, faVenus } from "@fortawesome/free-solid-svg-icons"
import { TAfindUsername } from "../services/userAPI"

const FindApplications = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle("Başvuru Bul"))
  }, [dispatch])

  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [page, setPage] = useState(1)
  const [id, setId] = useState("")
  const [instaUsername, setInstaUsername] = useState("")
  const [tiktokUsername, setTiktokUsername] = useState("")
  const [error, setError] = useState("")
  const [applications, setApplications] = useState([])
  const [loading, setLoading] = useState(false)
  const [autofillInsta, setAutofillInsta] = useState<string[]>([])

  const handleForm = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    const isNull = (array: any) => array.every((item: any) => item === "")

    let data
    if (id !== "" && isNull([instaUsername, tiktokUsername])) {
      data = `user=${id}`
    } else if (instaUsername !== "" && isNull([tiktokUsername, id])) {
      data = `insta_username=${instaUsername}`
    } else if (tiktokUsername !== "" && isNull([instaUsername, id])) {
      data = `tiktok_username=${tiktokUsername}`
    } else if (isNull([instaUsername, tiktokUsername, id])) {
      setError("Please provide any criteria")
      return
    } else {
      setError("Please provide only username or id")
      return
    }

    try {
      const response = await TAfindApplications(data)
      setApplications(response)
      setLoading(false)
    } catch (error) {
      setError("Error while searching applications")
      setLoading(false)
    }
  }

  useEffect(() => {
    const autofillUsername = async () => {
      const response = await TAfindUsername(instaUsername)
      setAutofillInsta(response)
    }
    if (instaUsername.length > 1) {
      autofillUsername()
    }
  }, [instaUsername])

  const handleSuggestionClick = (selectedUsername: any) => {
    setInstaUsername(selectedUsername)
    setAutofillInsta([])
  }

  return (
    <div className="flex flex-col w-full items-start">
      <form className="w-1/4">
        <h1>Find Applications</h1>
        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">User ID:</h2>
        <input
          type="text"
          placeholder="User ID"
          className="form-input text-sm"
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">IG Username:</h2>
        <input
          type="text"
          placeholder="Instagram username"
          className="form-input text-sm"
          value={instaUsername}
          onChange={(e) => setInstaUsername(e.target.value)}
        />
        {autofillInsta.length > 0 && instaUsername !== "" && (
          <ul className="suggestion-list" style={{ position: "absolute", zIndex: 9999 }}>
            {[...new Set(autofillInsta)].slice(0, 5).map((autofillUsername, index) => (
              <li
                key={index}
                className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                onClick={() => handleSuggestionClick(autofillUsername)}
              >
                {autofillUsername}
              </li>
            ))}
          </ul>
        )}
        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">Tiktok Username:</h2>
        <input
          type="text"
          placeholder="Tiktok username"
          className="form-input text-sm"
          value={tiktokUsername}
          onChange={(e) => setTiktokUsername(e.target.value)}
        />
        {error && <p className="text-red-500 text-sm text-center">{error}</p>}
        <div className="flex justify-center">
          <button type="button" onClick={handleForm} className="btn btn-primary mt-3">
            Submit
          </button>
        </div>
      </form>
      <div className="datatables mt-4 w-full overflow-x-auto">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={applications}
            columns={[
              {
                accessor: "campaign",
                title: "Campaign",
                render: ({ campaign }) => (
                  <a href={`/campaigns/find/${campaign}`} target="_blank" rel="noopener noreferrer">
                    <div className="text-center items-center">
                      <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
                    </div>
                  </a>
                ),
              },
              { accessor: "_id", title: "Application Id" },
              {
                accessor: "user",
                title: "User Id",
                render: ({ user }) => (
                  <a href={`/user/find/${user}`} target="_blank" rel="noopener noreferrer">
                    <div className="text-center items-center">
                      <span>{user}</span>
                    </div>
                  </a>
                ),
              },
              {
                accessor: "state",
                title: "State",
                render: ({ state }) => <div>{state}</div>,
              },
              { accessor: "price_brand", title: "Price Brand" },
              { accessor: "price_user", title: "Price User" },
              { accessor: "age", title: "Age" },
              {
                accessor: "gender",
                title: "Gender",
                render: ({ gender }) => (
                  <div className="text-center items-center">
                    {gender === "male" ? (
                      <FontAwesomeIcon icon={faMars} style={{ color: "#005eff" }} />
                    ) : gender === "female" ? (
                      <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
                    ) : (
                      <div>other</div>
                    )}
                  </div>
                ),
              },
              { accessor: "job", title: "Job" },
              { accessor: "country", title: "Country" },
              { accessor: "insta_full_name", title: "FullName" },
              {
                accessor: "insta_username",
                title: "Insta Username",
                render: ({ insta_username }) => (
                  <a
                    href={`https://www.instagram.com/${insta_username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="text-center items-center">
                      <span>{insta_username}</span>
                    </div>
                  </a>
                ),
              },
              {
                accessor: "tiktok_username",
                title: "Tiktok Username",
                render: ({ tiktok_username }) => (
                  <a
                    href={`https://www.tiktok.com/@${tiktok_username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="text-center items-center">
                      <span>{tiktok_username}</span>
                    </div>
                  </a>
                ),
              },
              { accessor: "insta_followers", title: "Insta Followers" },
              { accessor: "insta_average_like", title: "Insta Average Like" },
              { accessor: "insta_post_number", title: "Insta Post Number" },
              { accessor: "tiktok_followers", title: "Tiktok Followers" },
              { accessor: "tiktok_average_like", title: "Tiktok Average Like" },
              {
                accessor: "tiktok_engagement_rate",
                title: "Tiktok Engagement Rate",
              },
              { accessor: "tiktok_hearts", title: "Tiktok Hearts" },
            ]}
            totalRecords={applications.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default FindApplications
