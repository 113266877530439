import apiClient from "./axiosInstance"

export const TAgetPopularHashtagSearchPost = async () => {
  const response = await apiClient.get("/insta/insta-popular-hashtag-post/", {})
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find post failed")
  }
}

export const TAgetHashtagPostsLastThreeMonths = async (
  page: number,
  perPage: number,
  description: string,
) => {
  const response = await apiClient.get(
    `/insta/insta-hashtag-posts-three-months?page=${page}&perPage=${perPage}&description=${description}`,
    {},
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find post failed")
  }
}

export const TAgetMostContentUsernames = async (perPage: any, page: number) => {
  const response = await apiClient.get(
    `/insta/insta-most-content-usernames?perPage=${perPage}&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAgetTeamfluencerData = async () => {
  const response = await apiClient.get("/insta/insta-teamfluencer", {
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}
