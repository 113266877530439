import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { TAfindAllUser, TAremoveVerification } from "../services/userAPI"
import { WaitingApprovalUserData } from "../types/waitingApprovalUserData"
import { DataTable, DataTableSortStatus } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import { setPageTitle } from "../redux/store/themeConfigSlice"
import {
  Filters,
  FilterValue,
  FilterType,
  CountryFilterValue,
  CityFilterValue,
  JobFilterValue,
} from "../types/getAllUsersData"
// import DownloadPdfButton from '../components/DownloadPdfButton';
import DownloadCSVButton from "../components/DownloadCSVButton"
import KeywordData from "../JSON/KEYWORDS.json"
import HobbyData from "../JSON/HOBBIES.json"
import CountryData from "../JSON/COUNTRIES.json"
import CityData from "../JSON/CITIES.json"
import JobData from "../JSON/JOBS.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVenus, faMars, faEye, faStar } from "@fortawesome/free-solid-svg-icons"
import { setUserFilters, selectUserFilters } from "../redux/store/userFilterSlice"

const phoneNumberFixer = (phoneNumber: string) => {
  const fixedPhoneNumber = phoneNumber.slice(0, 13)
  return fixedPhoneNumber
}

const instaAverageLikeFixer = (instaAverageLike: number) => {
  if (!instaAverageLike) {
    return 0
  }
  const roundedNumber = Math.round(instaAverageLike * 10) / 10
  return roundedNumber
}

const tiktokAverageLikeFixer = (tiktokAverageLike: number) => {
  if (!tiktokAverageLike) {
    return 0
  }
  const roundedNumber = Math.round(tiktokAverageLike * 10) / 10
  return roundedNumber
}

const engagementRateFixer = (engagementRate: number) => {
  if (!engagementRate) {
    return 0
  }
  const roundedNumber = Math.round(engagementRate * 1000) / 1000
  return roundedNumber
}

const tiktokFollowersFixer = (tiktokEngagementRate: number) => {
  if (!tiktokEngagementRate) {
    return 0
  }
  return tiktokEngagementRate
}

const fetchData = async (
  page: number,
  perPage: number,
  query: any,
  sortBy: string,
  sortOrder: boolean,
  exportCSV: string,
) => {
  try {
    const response = await TAfindAllUser(page, perPage, query, sortBy, sortOrder, exportCSV)
    if (response && Array.isArray(response.users)) {
      const totalPages = response.totalPages
      const data = response.users.map((item: any, index: any) => {
        return {
          id: index + 1,
          name: item.name,
          email: item.email,
          age: item.age,
          job: item.job,
          city: item.city,
          country: item.country,
          hobbies: item.hobbies,
          phone: phoneNumberFixer(item.phone),
          gender: item.gender,
          profile_complete: item.profile_complete,
          "insta.full_name": item.insta?.full_name,
          "insta.username": item.insta?.username,
          "insta.post_number": item.insta?.post_number,
          "insta.followers": item.insta?.followers,
          "insta.following": item.insta?.following,
          "insta.average_like": instaAverageLikeFixer(item.insta?.average_like),
          "tiktok.username": item.tiktok?.username,
          "tiktok.followers": tiktokFollowersFixer(item.tiktok?.followers),
          "tiktok.following": tiktokFollowersFixer(item.tiktok?.following),
          "tiktok.videos": item.videos,
          "tiktok.tiktok_average_like": tiktokAverageLikeFixer(item.tiktok?.tiktok_average_like),
          "tiktok.tiktok_engagement_rate": engagementRateFixer(item.tiktok?.tiktok_engagement_rate),
          "tiktok.hearts": tiktokFollowersFixer(item.tiktok?.hearts),
          "insta.keywords": item.insta?.keywords,
          _id: item._id,
          verification: item.verification,
          ...item,
        }
      })
      return { data, totalPages }
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

const GetAllUsers = () => {
  const userFilters = useSelector(selectUserFilters)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle("Range Search Table"))
    const initialFilters = userFilters // assuming this comes from `useSelector(selectUserFilters)`
    if (
      Object.keys(initialFilters).length > 0 &&
      JSON.stringify(initialFilters) !== JSON.stringify(defaultState)
    ) {
      setFilterss(initialFilters)
    }
  }, [dispatch])

  const [userData, setUserData] = useState([] as WaitingApprovalUserData[])
  const [page, setPage] = useState(1)
  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [totalPages, setTotalPages] = useState(0)
  const [initialRecords, setInitialRecords] = useState(sortBy(userData, "id"))
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "id",
    direction: "asc",
  })
  const [error, setError] = useState<string | null>(null)
  const [keywords, setKeywords] = useState("")
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [autofillCountries, setAutofillCountries] = useState<string[]>([])
  const [country, setCountry] = useState("")
  const [autofillCities, setAutofillCities] = useState<string[]>([])
  const [city, setCity] = useState("")
  const [autofillJobs, setAutofillJobs] = useState<string[]>([])
  const [job, setJob] = useState("")
  const [isHobbyDropdownOpen, setIsHobbyDropdownOpen] = useState(false)
  const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false)
  const [selectingHobbies, setSelectingHobbies] = useState<string[]>([])
  const [sortByColumn, setSortByColumn] = useState("")
  const [sortOrder, setSortOrder] = useState(false)
  const hobbies = HobbyData.hobbies
  const cities = CityData.cities
  const countries = CountryData.countries
  const jobs = JobData.jobs
  const [exportCSV, setExportCSV] = useState("")

  const defaultState: Filters = {
    age: { min: "", max: "" },
    followers: { min: "", max: "" },
    average_like: { min: "", max: "" },
    tiktok_followers: { min: "", max: "" },
    tiktok_average_like: { min: "", max: "" },
    tiktok_engagement_rate: { min: "", max: "" },
    country: { value: "" },
    city: { value: "" },
    job: { value: "" },
    keywords: [],
    frequency: 0,
    hobbies: [],
    gender: "",
    verification: "",
    is_spam: "",
    unvisible: "",
    warnings: "",
    last_login: "",
  }
  const [filters, setFilterss] = useState<Filters>(defaultState)

  const setFilter = (
    key: keyof Filters,
    type: FilterType,
    value: string | string[] | ("male" | "female" | "" | "true" | "false") | number,
  ) => {
    if (key === "keywords") {
      setFilterss((prev) => ({ ...prev, [key]: value as string[] }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "hobbies") {
      const newHobbies = Array.isArray(value) ? value : [value]
      setFilterss((prev) => ({ ...prev, [key]: newHobbies } as Filters))
      dispatch(setUserFilters({ ...filters, [key]: newHobbies }))
    } else if (key === "frequency") {
      setFilterss((prev) => ({ ...prev, [key]: value as number }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "verification") {
      setFilterss((prev) => ({ ...prev, [key]: value as "true" | "false" | "" }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "gender") {
      setFilterss((prev) => ({ ...prev, [key]: value as "male" | "female" | "" }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "city") {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "country") {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "job") {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "is_spam") {
      setFilterss((prev) => ({ ...prev, [key]: value as "true" | "false" | "" }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "unvisible") {
      setFilterss((prev) => ({ ...prev, [key]: value as "true" | "false" | "" }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "warnings") {
      setFilterss((prev) => ({ ...prev, [key]: value as "true" | "false" | "" }))
      dispatch(setUserFilters({ ...filters }))
    } else if (key === "last_login") {
      setFilterss((prev) => ({ ...prev, [key]: value as string }))
      dispatch(setUserFilters({ ...filters }))
    } else {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setUserFilters({ ...filters }))
    }
  }

  useEffect(() => {
    dispatch(setUserFilters(filters))
  }, [filters, dispatch])

  const handleFetchData = async () => {
    setLoading(true)

    const flattenFilters = Object.entries(filters).reduce((acc, [key, filter]) => {
      if (key === "keywords" || key === "hobbies") {
        console.log()
      } else if (key === "gender" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "verification" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "is_spam" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "unvisible" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "warnings" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "last_login" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "frequency" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "country") {
        acc[key] = (filter as CountryFilterValue).value
      } else if (key === "city") {
        acc[key] = (filter as CityFilterValue).value
      } else if (key === "job") {
        acc[key] = (filter as JobFilterValue).value
      } else {
        const { min, max } = filter as FilterValue
        if (min) {
          acc[`min_${key}`] = min
        }
        if (max) {
          acc[`max_${key}`] = max
        }
      }

      return acc
    }, {} as { [key: string]: string })

    const params = new URLSearchParams(flattenFilters)
    const keywords = (filters.keywords as string[]).map(
      (keyword) => keyword.charAt(0).toUpperCase() + keyword.slice(1),
    )
    keywords.forEach((keywords) => {
      params.append("keywords", keywords)
    })

    const hobbies = (filters.hobbies as string[]).map((hobby) => hobby.charAt(0) + hobby.slice(1))
    hobbies.forEach((hobbies) => {
      params.append("hobbies", hobbies)
    })
    try {
      const data: any = await fetchData(page, pageSize, params, sortByColumn, sortOrder, exportCSV)
      if (data !== undefined) {
        setUserData(data.data)
        setInitialRecords(data.data)
        setTotalPages(data.totalPages)
      } else {
        setError("No data found")
      }
      setLoading(false)
    } catch (error) {
      setError("No data found")
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [page, pageSize, sortByColumn, sortOrder, exportCSV])

  const handleSorting = (sortyBy: string) => {
    setSortByColumn(sortyBy)
    setSortOrder((prevOrder) => !prevOrder)
  }

  const filterKeys: (keyof Filters)[] = [
    "age",
    "followers",
    "average_like",
    "tiktok_followers",
    "tiktok_average_like",
    "tiktok_engagement_rate",
    "country",
    "city",
    "job",
    "keywords",
    "frequency",
    "hobbies",
    "gender",
    "verification",
    "is_spam",
    "unvisible",
    "warnings",
    "last_login",
  ]

  const handleKeywordInputChange = (e: any) => {
    const inputKeywords = e.target.value
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
    setKeywords(inputKeywords)

    if (inputKeywords.length === 0) {
      setIsDropdownOpen(false)
    } else {
      setIsDropdownOpen(true)
    }
  }

  const autoCompleteKeywords = keywords.split(",").map((keyword) => keyword.trim())

  const lastKeyword = autoCompleteKeywords[autoCompleteKeywords.length - 1]

  const uniqueKeywords = [...new Set(KeywordData.keywords)]
  const filteredKeywords = uniqueKeywords
    .filter((keyword: string) => keyword.toLowerCase().startsWith(lastKeyword.toLowerCase()))
    .slice(0, 4)

  const autoCompleteKeyword: string[] = autoCompleteKeywords.length === 0 ? [] : filteredKeywords

  const handleCheckboxChange = (hobby: any) => {
    let updatedHobbies
    if (selectingHobbies.includes(hobby)) {
      updatedHobbies = selectingHobbies.filter((item) => item !== hobby)
    } else {
      updatedHobbies = [...selectingHobbies, hobby]
    }
    setSelectingHobbies(updatedHobbies)
    setFilter("hobbies", "value", updatedHobbies)
  }

  useEffect(() => {
    const handleClick = () => {
      setIsDropdownOpen(false)
    }
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  const formatKey = (key: string) => {
    switch (key) {
      case "age":
        return "Age"
      case "followers":
        return "IG Followers"
      case "average_like":
        return "IG Avg Like"
      case "tiktok_followers":
        return "TT Followers"
      case "tiktok_average_like":
        return "TT Avg Like"
      case "tiktok_engagement_rate":
        return "TT Eng. Rate"
      default:
        return key
    }
  }

  const renderBrandId = (record: any, index: any) => {
    const itemsPerPage = page * pageSize
    const recordIndex = itemsPerPage + index
    const brandId = recordIndex - pageSize + 1
    return <div>{brandId}</div>
  }

  const handleInputChangeCountry = (e: any) => {
    const value = e.target.value
    setFilter("country", "value", value)
    setCountry(value)

    if (value.length > 0) {
      const filteredCountries = countries
        .filter((country) => country.name.toLowerCase().startsWith(value.toLowerCase()))
        .map((country) => country.name)
      setAutofillCountries(filteredCountries)
    } else {
      setAutofillCountries([])
    }
  }

  const handleCountrySuggestionClick = (key: any, selectedCountry: any) => {
    setFilter(key, "value", selectedCountry)
    setCountry(selectedCountry)
    setAutofillCountries([])
  }

  const handleInputChangeCity = (e: any) => {
    const value = e.target.value
    setFilter("city", "value", value)
    setCity(value)

    if (value.length > 0) {
      const filteredCities = cities
        .filter((city) => city.name.toLowerCase().startsWith(value.toLowerCase()))
        .map((city) => city.name)
      setAutofillCities(filteredCities)
    } else {
      setAutofillCities([])
    }
  }
  const handleCitySuggestionClick = (key: any, selectedCity: any) => {
    setFilter(key, "value", selectedCity)
    setCity(selectedCity)
    setAutofillCities([])
  }

  const handleInputChangeJob = (e: any) => {
    const value = e.target.value
    setFilter("job", "value", value)
    setJob(value)

    if (value.length > 0) {
      const filteredJobs = jobs
        .filter((job) => job.name.toLowerCase().startsWith(value.toLowerCase()))
        .map((job) => job.name)
      setAutofillJobs(filteredJobs)
    } else {
      setAutofillJobs([])
    }
  }
  const handleJobSuggestionClick = (key: any, selectedJob: any) => {
    setFilter(key, "value", selectedJob)
    setJob(selectedJob)
    setAutofillJobs([])
  }

  const removeVerification = (id: any) => {
    return () => {
      TAremoveVerification(id)
    }
  }

  return (
    <div className="panel">
      <div className="flex md:items-center md:flex-row flex-col">
        <div className="flex flex-col justify-center text-center"></div>
        <div className="md:flex md:flex-row w-full">
          {filterKeys.map((key) => {
            if (key === "gender") {
              return (
                <div key={key} className="md:flex md:flex-col mr-2 ml-2">
                  <h2 className="text-sm font-bold mb-2">Gender</h2>
                  <label>
                    <input
                      type="radio"
                      value="male"
                      checked={filters.gender === "male"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Male
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="female"
                      checked={filters.gender === "female"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Female
                  </label>
                  <label>
                    <input
                      type="radio"
                      value=""
                      checked={filters.gender === ""}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Any
                  </label>
                </div>
              )
            } else if (key === "verification") {
              return (
                <div key={key} className="md:flex md:flex-col mr-2 ml-2">
                  <h2 className="text-sm font-bold mb-2">Verification</h2>
                  <label>
                    <input
                      type="radio"
                      value="true"
                      checked={filters.verification === "true"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    True
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="false"
                      checked={filters.verification === "false"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    False
                  </label>
                  <label>
                    <input
                      type="radio"
                      value=""
                      checked={filters.verification === ""}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Any
                  </label>
                </div>
              )
            } else if (key === "is_spam") {
              return (
                <div key={key} className="md:flex md:flex-col mr-2 ml-2">
                  <h2 className="text-sm font-bold mb-2">Is Spam</h2>
                  <label>
                    <input
                      type="radio"
                      value="true"
                      checked={filters.is_spam === "true"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    True
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="false"
                      checked={filters.is_spam === "false"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    False
                  </label>
                  <label>
                    <input
                      type="radio"
                      value=""
                      checked={filters.is_spam === ""}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Any
                  </label>
                </div>
              )
            } else if (key === "unvisible") {
              return (
                <div key={key} className="md:flex md:flex-col mr-2 ml-2">
                  <h2 className="text-sm font-bold mb-2">Unvisible</h2>
                  <label>
                    <input
                      type="radio"
                      value="true"
                      checked={filters.unvisible === "true"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    True
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="false"
                      checked={filters.unvisible === "false"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    False
                  </label>
                  <label>
                    <input
                      type="radio"
                      value=""
                      checked={filters.unvisible === ""}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.value)
                      }}
                      className="form-radio text-pink-600 mr-2"
                    />
                    Any
                  </label>
                </div>
              )
            } else if (
              key !== "country" &&
              key !== "keywords" &&
              key !== "city" &&
              key !== "hobbies" &&
              key !== "job" &&
              key !== "frequency" &&
              key !== "warnings" &&
              key !== "last_login"
            ) {
              return (
                <div
                  key={key}
                  className="md:flex md:flex-col flex-1 mr-2 justify-center items-center"
                >
                  <h2 className="text-sm font-bold mb-2">{formatKey(key)}</h2>
                  <input
                    type="text"
                    value={(filters[key] as FilterValue).min}
                    onChange={(e) => {
                      setFilter(key, "min", e.target.value)
                    }}
                    className="form-input w-full mb-2"
                    placeholder={`min. ${key}`}
                  />

                  <input
                    type="text"
                    value={(filters[key] as FilterValue).max}
                    onChange={(e) => {
                      setFilter(key, "max", e.target.value)
                    }}
                    className="form-input w-full"
                    placeholder={`max. ${key}`}
                  />
                </div>
              )
            }
          })}
        </div>
      </div>
      <div className="flex w-full justify-between flex-end">
        <div className="flex flex-row w-2/3 items-center">
          <div className="md:flex md:flex-row">
            {filterKeys.map((key) => {
              if (key === "country") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 justify-center items-center">
                      <h2 className="text-sm font-bold mb-1 mt-3">Country</h2>
                      <input
                        type="text"
                        value={filters[key].value}
                        onChange={handleInputChangeCountry}
                        className="form-input"
                        placeholder={`${key.charAt(0).toUpperCase() + key.slice(1)} name`}
                      />
                    </div>
                    {autofillCountries.length > 0 && country.length > 0 && (
                      <ul
                        className="suggestion-list"
                        style={{ position: "absolute", zIndex: 9999 }}
                      >
                        {[...new Set(autofillCountries)]
                          .slice(0, 5)
                          .map((autofillCountry, index) => (
                            <li
                              key={index}
                              className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                              onClick={() =>
                                handleCountrySuggestionClick("country", autofillCountry)
                              }
                            >
                              {autofillCountry}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                )
              } else if (key === "city") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 justify-center items-center">
                      <h2 className="text-sm font-bold mb-1 mt-3">City</h2>
                      <input
                        type="text"
                        value={filters[key].value}
                        onChange={handleInputChangeCity}
                        className="form-input"
                        placeholder={`${key.charAt(0).toUpperCase() + key.slice(1)} name`}
                      />
                    </div>
                    {autofillCities.length > 0 && city.length > 0 && (
                      <ul
                        className="suggestion-list"
                        style={{ position: "absolute", zIndex: 9999 }}
                      >
                        {[...new Set(autofillCities)].slice(0, 5).map((autofillCity, index) => (
                          <li
                            key={index}
                            className="bg-white ml-4 p-2 text-black cursor-pointer hover:bg-gray-200"
                            onClick={() => handleCitySuggestionClick("city", autofillCity)}
                          >
                            {autofillCity}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )
              } else if (key === "job") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 justify-center items-center">
                      <h2 className="text-sm font-bold mb-1 mt-3">Job</h2>
                      <input
                        type="text"
                        value={filters[key].value}
                        onChange={handleInputChangeJob}
                        className="form-input"
                        placeholder={"Job name"}
                      />
                    </div>
                    {autofillJobs.length > 0 && job.length > 0 && (
                      <ul
                        className="suggestion-list"
                        style={{ position: "absolute", zIndex: 9999 }}
                      >
                        {[...new Set(autofillJobs)].slice(0, 5).map((autofillJob, index) => (
                          <li
                            key={index}
                            className="bg-white p-2 ml-6 text-black cursor-pointer hover:bg-gray-200"
                            onClick={() => handleJobSuggestionClick("job", autofillJob)}
                          >
                            {autofillJob}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )
              } else if (key === "keywords") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 justify-center items-center">
                      <h2 className="text-sm font-bold mb-1 mt-3">Keywords</h2>
                      <input
                        type="text"
                        value={filters[key].join(",")}
                        onChange={(e) => {
                          const keywords = e.target.value.split(",").map((word) => {
                            const trimmedWord = word.trim()
                            return (
                              trimmedWord.charAt(0).toUpperCase() +
                              trimmedWord.slice(1).toLowerCase()
                            )
                          })
                          setFilter(key, "value", keywords)
                          handleKeywordInputChange(e)
                        }}
                        className="form-input"
                        placeholder={"Keyword1, Keyword2, ..."}
                      />
                      {isDropdownOpen && keywords.length > 0 && (
                        <div>
                          <ul
                            className="suggestion-list"
                            style={{ position: "absolute", zIndex: 9999 }}
                          >
                            {autoCompleteKeyword.map((keyword, index) => (
                              <li
                                key={index}
                                className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                                onClick={() => {
                                  const currentInput = filters[key].join(", ")

                                  if (currentInput.includes(",")) {
                                    const parts = currentInput.split(",")
                                    parts[parts.length - 1] = keyword
                                    setFilter(
                                      key,
                                      "value",
                                      parts.map((part) => part.trim()),
                                    )
                                  } else {
                                    setFilter(key, "value", [keyword])
                                  }
                                  setIsDropdownOpen(false)
                                }}
                              >
                                {keyword}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                )
              } else if (key === "frequency") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 justify-center items-center">
                      <h2 className="text-sm font-bold mb-1 mt-3">Frequency</h2>
                      <input
                        type="number"
                        value={filters[key]}
                        onChange={(e) => {
                          setFilter(key, "value", e.target.value)
                        }}
                        className="form-input"
                        placeholder={"3"}
                      />
                    </div>
                  </div>
                )
              } else if (key === "hobbies") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 mt-3 justify-center items-center">
                      <button
                        className=" inline-flex items-center justify-center mt-5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 px-3"
                        onClick={() => setIsHobbyDropdownOpen(!isHobbyDropdownOpen)}
                      >
                        Select Hobbies
                      </button>
                      {isHobbyDropdownOpen && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                          <div className="bg-white p-4 rounded shadow-lg w-full max-w-xs mx-auto">
                            <h2 className="text-lg font-bold mb-4">Select Hobbies</h2>
                            <div className="max-h-64 overflow-y-auto">
                              {hobbies.map((hobby, index) => (
                                <div key={index} className="mb-2">
                                  <label className="flex items-center">
                                    <input
                                      type="checkbox"
                                      name="hobbies"
                                      value={hobby}
                                      checked={selectingHobbies.includes(hobby)}
                                      onChange={() => handleCheckboxChange(hobby)}
                                      className="mr-2"
                                    />
                                    {hobby}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <button
                              className="mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                              onClick={() => setIsHobbyDropdownOpen(false)}
                            >
                              Save
                            </button>
                            <button
                              className="mt-4 ml-12 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                              onClick={() => setIsHobbyDropdownOpen(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              } else if (key === "warnings") {
                return (
                  <div
                    key={key}
                    className="md:flex md:flex-col flex-1 mb-4 ml-5 mt-3 justify-center items-center"
                  >
                    <h2 className="text-sm font-bold mb-2">Warnings</h2>
                    <input
                      type="checkbox"
                      checked={filters.warnings === "true"}
                      onChange={(e) => {
                        setFilter(key, "value", e.target.checked ? "true" : "")
                      }}
                      className="form-checkbox text-pink-600"
                    />
                  </div>
                )
              } else if (key === "last_login") {
                return (
                  <div key={key}>
                    <div className="md:flex md:flex-col flex-1 mb-4 ml-5 mt-3 justify-center items-center">
                      <button
                        className=" inline-flex items-center justify-center mt-5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 px-3"
                        onClick={() => setIsLoginDropdownOpen(!isLoginDropdownOpen)}
                      >
                        Last Login
                      </button>
                      {isLoginDropdownOpen && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                          <div className="bg-white p-4 rounded shadow-lg w-full max-w-xs mx-auto">
                            Last Login
                            <h2 className="text-lg font-bold mb-4"></h2>
                            <div className="max-h-64 overflow-y-auto">
                              {["1 Month", "3 Months", "6 Months", "1 Year"].map((label, index) => {
                                const monthsAgo = [1, 3, 6, 12]
                                const value = new Date(
                                  new Date().setMonth(new Date().getMonth() - monthsAgo[index]),
                                ).toLocaleDateString("en-CA")

                                return (
                                  <label key={label}>
                                    <input
                                      type="radio"
                                      value={value}
                                      checked={filters.last_login === value}
                                      onChange={(e) => {
                                        setFilter(key, "value", e.target.value)
                                      }}
                                      className="form-radio text-pink-600 mr-2"
                                    />
                                    {label}
                                  </label>
                                )
                              })}
                            </div>
                            <button
                              className="mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                              onClick={() => setIsLoginDropdownOpen(false)}
                            >
                              Save
                            </button>
                            <button
                              className="mt-4 ml-12 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                              onClick={() => setIsLoginDropdownOpen(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>

        <div className="flex flex-row justify-end text-center w-1/3 mb-4 mr-2">
          <button
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            onClick={() => handleFetchData()}
          >
            Search Users
          </button>
          <button
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            onClick={() => setExportCSV("true")}
          >
            10 Page Search
          </button>
          {/* <DownloadPdfButton
            className=" inline-flex items-center justify-center px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            userData={initialRecords}
          /> */}
          <DownloadCSVButton
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            userData={initialRecords}
          />
        </div>
      </div>

      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={initialRecords}
            columns={[
              { accessor: "id", title: "Id", sortable: true, render: renderBrandId },
              {
                accessor: "verification",
                title: "Verified",
                sortable: true,
                render: ({ verification, _id }: any) => (
                  <div className="text-center items-center">
                    {verification ? (
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#ffba00", cursor: "pointer" }}
                        onClick={() => removeVerification(_id)}
                      />
                    ) : null}
                  </div>
                ),
              },
              {
                accessor: "details",
                title: "Details",
                sortable: true,
                render: ({ _id }: any) => (
                  <a href={`/user/find/${_id}`} target="_blank" rel="noopener noreferrer">
                    <div className="text-center items-center">
                      <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
                    </div>
                  </a>
                ),
              },
              {
                accessor: "firstName",
                title: "Name",
                sortable: true,
                render: ({ name }) => <div>{name}</div>,
              },
              {
                accessor: "phone",
                title: "Phone",
                sortable: true,
                render: ({ phone }) => {
                  return phone && phone.length < 19 ? (
                    <div>{phone}</div>
                  ) : (
                    <div>{`${phone?.slice(0, 19)}..`}</div>
                  )
                },
              },

              { accessor: "age", title: "Age", sortable: true },
              { accessor: "job", title: "Job", sortable: true },
              { accessor: "insta.full_name", title: "FullName", sortable: true },
              {
                accessor: "insta.username",
                title: "Insta Username",
                sortable: true,
                render: ({ insta }: any) => (
                  <a
                    href={`https://www.instagram.com/${insta?.username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="text-center items-center">
                      <span>{insta.username}</span>
                    </div>
                  </a>
                ),
              },
              {
                accessor: "tiktok.username",
                title: "Tiktok Username",
                sortable: true,
                render: ({ tiktok }: any) => (
                  <a
                    href={`https://www.tiktok.com/@${tiktok?.username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="text-center items-center">
                      <span>{tiktok?.username}</span>
                    </div>
                  </a>
                ),
              },
              {
                accessor: "gender",
                title: "Gender",
                sortable: true,
                render: ({ gender }) => (
                  <div className="text-center items-center">
                    {gender === "male" ? (
                      <FontAwesomeIcon icon={faMars} style={{ color: "#005eff" }} />
                    ) : gender === "female" ? (
                      <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
                    ) : null}
                  </div>
                ),
              },
              { accessor: "country", title: "Country", sortable: true },
              { accessor: "keywordCount", title: "Frequency", sortable: true },
              { accessor: "insta.followers", title: "Insta Followers", sortable: true },
              { accessor: "insta.following", title: "Insta Following", sortable: true },
              { accessor: "insta.average_like", title: "Insta Average Like", sortable: true },
              { accessor: "insta.post_number", title: "Insta Post Number", sortable: true },
              { accessor: "tiktok.followers", title: "Tiktok Followers", sortable: true },
              { accessor: "tiktok.following", title: "Tiktok Following", sortable: true },
              {
                accessor: "tiktok.tiktok_average_like",
                title: "Tiktok Average Like",
                sortable: true,
              },
              {
                accessor: "tiktok.tiktok_engagement_rate",
                title: "Tiktok Engagement Rate",
                sortable: true,
              },
              { accessor: "tiktok.hearts", title: "Tiktok Hearts", sortable: true },
            ]}
            totalRecords={totalPages * pageSize}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={(e) => handleSorting(e.columnAccessor)}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default GetAllUsers
